<template>
  <v-navigation-drawer
    v-model="isMenuActive"
    class="nav-drawer text-left"
    bottom
    temporary
    width="300"
  >
    <v-list>
      <v-list-item
        v-for="item in topLevelItems"
        :key="item.title"
        :prepend-icon="item.icon"
        :title="item.title"
        :to="item.to"
        link
      ></v-list-item>

      <v-list-group v-for="group in groups" :key="group.title">
        <template #activator="slot">
          <v-list-item
            v-bind="slot.props"
            :title="group.title"
            :prepend-icon="group.icon"
          ></v-list-item>
        </template>

        <v-list-item
          v-for="item in group.items"
          :key="item.title"
          :value="item.title"
          :title="item.title"
          :to="item.to"
          link
        ></v-list-item>
      </v-list-group>

      <v-spacer />
      <v-divider />

      <v-list-item prepend-icon="$signOut" @click="auth.signOut">
        Sign Out
      </v-list-item>
      <SfiThemeToggle rounded="0" />
    </v-list>
  </v-navigation-drawer>
</template>

<script setup lang="ts">
import { useAuth } from '@simplifi/auth'
import { useNav } from '@simplifi/components'

const auth = useAuth()
const { isMenuActive } = useNav()

const topLevelItems = [
  {
    title: 'Home',
    icon: '$home',
    to: { name: 'home' },
  },
  {
    title: 'Pull Requests',
    icon: '$pullRequest',
    to: { name: 'pull-requests' },
  },
  {
    title: 'Configuration',
    icon: '$configurationKey',
    to: { name: 'configuration' },
  },
  {
    title: 'Secrets',
    icon: '$secret',
    to: { name: 'secrets' },
  },
]

const groups = [
  {
    title: 'Features',
    icon: '$feature',
    items: [
      {
        title: 'Rollout',
        to: { name: 'features-rollout' },
      },
      {
        title: 'Active',
        to: { name: 'features-active' },
      },
      {
        title: 'Permanent',
        to: { name: 'features-permanent' },
      },
      {
        title: 'Deprecated',
        to: { name: 'features-deprecated' },
      },
    ],
  },
  {
    title: 'Deployments',
    icon: '$deployment',
    items: [
      {
        title: 'Overview',
        to: { name: 'deployment-overview' },
      },
      {
        title: 'Recent',
        to: { name: 'deployments' },
      },
    ],
  },
]
</script>

<style scoped>
/* Style list as flex so we can push things to the bottom easily */
/* Also allow overflow-y so if the menu gets large / used on mobile we can scroll it */
.v-list {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  overflow-y: auto !important;
  padding: 0;
}
/* Prevent menu items being visually selectable */
.v-list-item-title {
  user-select: none;
}
</style>
