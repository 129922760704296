<template>
  <SfiNavBar
    app-title="SIMPLIFi Development"
    logo="/logo.svg"
    :page-title="route.meta.title"
    :session="session"
    :apps="session.applications"
    @signOut="signOut"
  />
  <SideBar />
  <v-main class="d-flex flex-grow-1 overflow-y-auto flex-column">
    <div class="pa-8">
      <router-view v-slot="{ Component }">
        <Transition :mode="'out-in'" :appear="true">
          <Suspense>
            <div>
              <component :is="Component" />
            </div>
            <template #fallback>
              <v-progress-circular />
            </template>
          </Suspense>
        </Transition>
      </router-view>
    </div>
  </v-main>
</template>

<script setup lang="ts">
import { useAuth } from '@simplifi/auth'
import SideBar from '@/components/nav/side-bar.vue'
import { useRoute } from 'vue-router'

const auth = useAuth()
const route = useRoute()

const { session, signOut } = auth
</script>
