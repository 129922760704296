import {
  mdiAccountCheck,
  mdiClockTimeSevenOutline,
  mdiCogs,
  mdiGithub,
  mdiKey,
  mdiKeyVariant,
  mdiMicrosoftAzureDevops,
  mdiPlusMinus,
  mdiRocketLaunch,
  mdiSourcePull,
  mdiSourceRepository,
  mdiToggleSwitch,
  mdiTrashCan,
  mdiCheckCircleOutline,
} from '@mdi/js'
import type { VuetifyOptions } from 'vuetify'

export const vuetifyOptions: VuetifyOptions = {
  icons: {
    aliases: {
      // Entities
      configurationKey: mdiCogs,
      deployment: mdiRocketLaunch,
      diff: mdiPlusMinus,
      feature: mdiToggleSwitch,
      key: mdiKey,
      pullRequest: mdiSourcePull,
      repository: mdiSourceRepository,
      reviewer: mdiAccountCheck,
      secret: mdiKeyVariant,
      checkCircle: mdiCheckCircleOutline,

      // Statuses
      age: mdiClockTimeSevenOutline,
      unused: mdiTrashCan,

      // Brands
      azureDevops: mdiMicrosoftAzureDevops,
      github: mdiGithub,
    },
  },
}
