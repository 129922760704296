<template>
  <v-layout full-height class="flex-column ga-4 justify-center align-center">
    <h1>
      <span>
        <img src="/logo.svg" width="48" />
        <span class="title">SIMPLIFi Development</span>
      </span>
      <v-progress-linear indeterminate rounded color="secondary" />
    </h1>
  </v-layout>
</template>

<script setup lang="ts"></script>

<style lang="scss" scoped>
.title {
  vertical-align: top;
  margin-left: 12px;
}
</style>
